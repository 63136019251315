// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: require('../../package.json').version,
  build: require('../../package.json').build,
  lastSupportedVersion: '0.30.0',
  device: "web",

  apiLink: 'https://labapi.everleagues.com/api',
  apiEsignLink: 'https://ellab-esign.azurewebsites.net/api',
  roomHubLink: 'https://labapi.everleagues.com/roomhub',
  orgHubLink: 'https://labapi.everleagues.com/orghub',
  systemHubLink: 'https://labapi.everleagues.com/systemhub',
  storageLink: 'https://ellabstorage.blob.core.windows.net/',
  meetDefaultUrl: 'https://testconf3.everleagues.com',
  apirpLink: "https://elrpapi-lab.azurewebsites.net/api/v1/",
  apiclmLink: "https://elclmservicelabapi.azurewebsites.net/api/v1/",
  apiELNetLink: "https://elnetlabapi.everleagues.com/",
  publicStorageLink: "https://ellabpublicstorage.blob.core.windows.net/",
  apipsLink: "https://elpsadminapi-lab.azurewebsites.net/api/v1/",
  apiLibreOfficeLink:"https://eltoolsofficelabapi.everleagues.com/",
  apiLibreOfficeOrigin: "https://eltoolsofficelabapi.everleagues.com",
  fileServerLink: "https://ellabfileserver.everleagues.com",
  turnstileEnabled: false,
  turnstileSiteKey: '0x4AAAAAAAFWl6DKHe3-9iT0',
  apidmsLink:"https://eldmslabadminapi.azurewebsites.net/api/v1/",
  //localhost
  //adobePDFEmbedAPIClientID: '8c0cd670273d451cbc9b351b11d22318',
  //lab.everleagues.com
  //adobePDFEmbedAPIClientID: '293168fd2ddd4dd39b4edb39972f3e46',
  //dmslab.everleagues.com
  adobePDFEmbedAPIClientID: '7302e119312a4d378cb5da21cc5ee578',


  firebase: {
    appId: "1:269334807478:android:15c608b1fdca4cce",
    apiKey: "AIzaSyC1BGBjtJdo-c7hGlRSOItnBVizKsG4_Rc",
    authDomain: "everleagues1.firebaseapp.com",
    databaseURL: "https://everleagues1.firebaseio.com",
    projectId: "everleagues1",
    storageBucket: "everleagues1.appspot.com",
    messagingSenderId: "269334807478"
  },

  googlePicker: {
    developerKey: "AIzaSyAujiCBDx6oTvhrx7_Trq7ezzU79h65nN8",
    clientId: "269334807478-gbsp0qnhhlsusn652154gggv19nam09j.apps.googleusercontent.com",
    appIds: "269334807478",
    scope: ['https://www.googleapis.com/auth/drive.file'],
  },
  stripePublishKey: 'pk_test_V3UmIUbv2DgHLS0P9mSHvPza00fx1nrxqK',
  recaptchaSiteKey: "6LcCPMsUAAAAAGGUr-Ar_ZEThII2RpWwM14H074_"
};
