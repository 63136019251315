import { importProvidersFrom, Injectable } from '@angular/core';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { AuthService } from '../auth/auth.service';
// import { Router } from '@angular/router';
// import { AuthStateSelector } from '../states/auth.state.selector';
// import { SubSink } from 'subsink';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { analyzeAndValidateNgModules } from '@angular/compiler';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../../../core/localStorage/local-storage.service';
import { AbstractStateSelector } from '../../../core/states/abstract.state.selector';
import { LibreOfficeRequestsService } from './libreoffice-requests.service';
import { threadId } from 'worker_threads';
import { MyTypeResult, AzureStorageWopiActionType,  AzureStorageModel, WopiParameter } from '../../model/libreoffice.model';
import { environment } from '../../../../environments/environment';
import { OrgHub } from '../../../core/hub/org.hub';
import { UserDataSelector } from "../../states/user-data.state.selector";

@Injectable({
  providedIn: 'root'
})

export class LibreOfficeService {

//apiURL: string = 'https://elofficedev.azurewebsites.net/wopi';
apiURL: string;
postMessageOrigin: string;

constructor(private libreofficeRequest: LibreOfficeRequestsService, 
              private http: HttpClient, 
              public localauthstate: LocalStorageService, 
              private authstate: AbstractStateSelector,
              private orgHub: OrgHub,
              private userDataSelector: UserDataSelector,) 
  {
    // this._subs = new SubSink();
    this.apiURL = environment.apiLibreOfficeLink;
    this.postMessageOrigin = environment.apiLibreOfficeOrigin;
  }

  getPostMessageOrigin() {
    return this.postMessageOrigin;
  }

  getELOptions() {
    const token = this.authstate.getAuthData().accessToken;
    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/json');
    header = header.append('Accept', 'application/json');
    header = header.append('Authorization', 'Bearer ' + token);
    console.log(header);
    return { 'headers': header };
  }

  getUserName():string {
    let userName: string = "";
    if (this.authstate.getCurrentUser() !=null) {
      userName = this.authstate.getCurrentUser().firstName +" " + this.authstate.getCurrentUser().lastName;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        userName = this.localauthstate.getAuth().userName;
      }else {
        userName ='Unknown User';
      }
    }  
    
    return userName;

  }

  getUserId():string {
    let userId: string = "";
    if (this.authstate.getCurrentUser() !=null) {
      userId = this.authstate.getCurrentUser().userId;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        userId = this.localauthstate.getAuth().userId;
      }else {
        if (this.userDataSelector.userId != null) {
          userId = this.userDataSelector.userId;
        }else {
            userId ='Unknown User ID';
        }
      }
    }   
    return userId;
  }

  getUserEmail():string {
    let userEmail: string = "";
    if (this.authstate.getCurrentUser() !=null) {
      userEmail = this.authstate.getCurrentUser().email;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        userEmail = this.localauthstate.getAuth().email;
      }else {
        if (this.userDataSelector.userProfile.email != null) {
          userEmail = this.userDataSelector.userProfile.email;
        }else {
          userEmail ='';
        }
      }
    }   
    return userEmail;
  }

  getUserAvatar(userId: string): string {    
    let userAvatarURL:string = "";
    if (userId !=null) {
      if (this.userDataSelector !=null) {
        userAvatarURL = this.userDataSelector.userProfile.imageUrl;
      } else {
        this.orgHub.getUserPublicProfile(userId).then((res) => {
          userAvatarURL = res.imageUrl;
        });
      }
    }
    return userAvatarURL;
  }

  isWopiSupportedFileType(InputData: string): Observable<MyTypeResult> {
    //return  this.http.get<MyTypeResult>(this.apiURL+ '/check/'+ InputData);
    return this.libreofficeRequest.get({
      uri: 'wopi/check/' + InputData,
    });
  }  

  getAccessToken(InputData: string): Observable<MyTypeResult> {
    //return  this.http.get<MyTypeResult>(this.apiURL+ '/check/'+ InputData);
    return this.libreofficeRequest.get({
      uri: 'wopi/getToken/' + InputData,
    });
  }  

  getView(InputData: string): Observable<string> {
    //return  this.http.get<MyTypeResult>(this.apiURL+ '/check/'+ InputData);
    return this.libreofficeRequest.get({
      uri: 'home/detail/' + InputData,
      headerResponseType:"text/html"
    });
  }  
  
  saveDMSDFile(dataInfo: any, file:Blob, fileName: any):Observable<any>{
    const formData: FormData = new FormData();
    console.log ("dms file name: " + fileName);
    formData.append('file', file, fileName? fileName: "default.pdf");
    return this.libreofficeRequest.post({
      uri: 'wopi/dms/files/contents/' + dataInfo,
      data: formData,
      headerResponseType:"text/html"
    });
  }

}