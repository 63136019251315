import { InjectionToken, Injector } from "@angular/core";
import { MenuItem } from "../../core/ui/menu-item";
import { Plugin } from "../plugin.abstract";
// import { SampleService } from "./services/sample.service";
import * as _ from "lodash";
import { ToolsAuthService } from "../common-services/tools-auth.service";
import { AbstractStateSelector } from "../../core/states/abstract.state.selector";
import { ToolsLocalStateSelector } from "../common-services/tools-localstate.selector";
import { Router } from '@angular/router';

export class DocumentManagementPlugin extends Plugin {

    userRole: string;

    private _launchToRedirectEnabled: boolean = true;
    get tapRedirectPath(): string {
        return null;
    }
    get launchToRedirectPath(): string {

        console.log("launch")
        console.log(this.userRole)
        // return "/main/tools/reproduction/eggdonor/medicalscreening";
        // if (this.userRole === 'client') {
        //     console.log("CLIENT")
        //     return "clm/clmadmin";
        // }

        // if (this.userRole === 'owner' || this.userRole === 'admin' || this.userRole === 'co-worker') {
        //     console.log("OTHER")
        //     return "clm/clmadmin";
        // }

        return null;

    }
    get launchToRedirectPriority(): number {
        return 99;
    }
    get launchToRedirectEnabled(): boolean {
        // return false;
        //!uncomment before release
        return this._launchToRedirectEnabled;
    }
    get appId(): string {
        return "ext-documentManagement-plugin";
    }
    get path(): string {
        return "dms";
    }
    get iconUrl(): string {
        return "account_balance";
    }
    get loadModule(): Promise<any> {
        return import('./documentManagement.module').then(m => m.DocumentManagementModule);
    }

    private _authService: ToolsAuthService;
    private _elstateselector: AbstractStateSelector;
    private _toolstateselector: ToolsLocalStateSelector;
    private _rootMenu: MenuItem;
    private _currentFeeds: any;
    private router: Router;


    constructor(injector: Injector,) {
        super(injector);

        /// === Use parent inject method to inject your custom service class ==
        /// The injected service provider should not assume to be single instance,
        /// the Angular injector may create different instance because of the lazy load module.
        if (!this._authService) {
            this._authService = this.inject(ToolsAuthService);
            console.log("[documentManagerplugin] %s, injected: %o", this.appId, this._authService);
        }
        if (!this._elstateselector) {
            this._elstateselector = this.inject(AbstractStateSelector);
        }
        if (!this._toolstateselector) {
            this._toolstateselector = this.inject(ToolsLocalStateSelector);
        }

        if (!this.router) {
            this.router = this.inject(Router);
        }

        // init current feeds
        // this._currentFeeds = this._sampleService.getFeeds();
    }


    onOrgSwitched(args: any): Promise<void> {
        if (!args) { return Promise.resolve(); }

        let userId = args.userId;
        let industryId = args.industryId.toUpperCase();
        let role = args.role.toUpperCase();
        this.userRole = role.toLowerCase()
        console.log("switch")
        console.log(this.userRole)

        console.log("[document management Plugin] %s, onOrgSwitched: %o", this.appId, { userId, industryId, role });
        this._rootMenu = MenuItem.create(this.appId, this.path, this.iconUrl, "Documents");
        this._rootMenu.isEnabled = (industryId !== "PERSONAL")
        //&& (industryId === 'REPRODUCTIVE');
        this._launchToRedirectEnabled = this._rootMenu.isEnabled;
        this.buildSubMenu(this._rootMenu, industryId, role);
        this.onMenuUpdated$.next([this._rootMenu]);
        return Promise.resolve();
    }

    /*
             <px-gdn-sideitem title="Egg Donation Service" [routerLinkUrl]="['reproduction/edadmin']" >
            <mat-icon>assignment</mat-icon>
          </px-gdn-sideitem>
          <px-gdn-sideitem title="Surrogacy Service" [routerLinkUrl]="['reproduction/gcadmin']" >
            <mat-icon>assignment</mat-icon>
          </px-gdn-sideitem>
          <px-gdn-sideitem title="Egg Donation Form 2" [routerLinkUrl]="['reproduction/edform2']" >
            <mat-icon>assignment</mat-icon>
          </px-gdn-sideitem>
          <px-gdn-sideitem title="Surrogacy Form 2" [routerLinkUrl]="['reproduction/gcform2']" >
            <mat-icon>assignment</mat-icon>
          </px-gdn-sideitem>
          */
    buildSubMenu(rootmenu: MenuItem, industry, role) {

        // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/edadmin', "assignment", "Egg Donation Service" ));
        // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/gcadmin', "assignment", "Surrogacy Service" ));
        // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/edform2', "assignment", "Egg Donation Form 2" ));
        // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/gcform2', "assignment", "Surrogacy Form 2" ));
        rootmenu.subMenus.push(MenuItem.create(this.appId, 'dms/dmsadmin', "assignment","Documents"));
        rootmenu.subMenus.push(MenuItem.create(this.appId, 'dms/dmssetting', "assignment", "Settings"));

        // if ((role === 'ADMIN' || role === 'OWNER' || role === 'COWORKER')) {
        //     rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/edadmin', "assignment", "Contracts"));
        //     rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/gcadmin', "assignment", "Settings"));
        //    //rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/archive/eggdonor', "assignment", "Egg Donation Archive"));
        // }

        // if (role === 'CLIENT'
        //     && industry === 'REPRODUCTIVE') {
        //     // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/edform2', "assignment", "Egg Donor Application Form"));
        //     var tenantName = this.getTenantName(this._authService.getOrgUnitID());
        //     console.log('Reproduction tenant name:')
        //     console.log(tenantName);
        //     console.log('org unit id' + this._authService.getOrgUnitID());
        //     if (tenantName != null) {
        //         this._authService.getToolsEDReproductiveEnvironment(tenantName).subscribe(res => {
        //             console.log("Get egg donar form2 details with in plugin for tenant:" + tenantName);
        //             console.log(res);
        //             if (res !== null) {
        //                 rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/eggdonor/application',
        //                     "assignment", "Application"));
        //                 rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/eggdonor/medicalscreening',
        //                     "assignment", "Screening"));
        //                 // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/eggdonor/physicianconsultation',
        //                 //     "assignment", "Physical Consultation"));
        //                 // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/eggdonor/eggbanking', "assignment", "Egg Banking"));
        //             }
        //         });
        //     }
        //     // rootmenu.subMenus.push(MenuItem.create(this.appId, 'reproduction/gcform2', "assignment", "Surrogate Application Form"));
        // }
    }
    /*
    getTenantName(orgUnitId: string) {
        let tenantName = null;
        switch (orgUnitId.toLowerCase()) {
            //prod
            case "30a5704a-28c1-4c02-9573-66b8bebd35a6".toLowerCase():
                tenantName = "rsmc";
                break;
            case "156FD788-0DA1-4051-99B9-5756C2A30196".toLowerCase():
                tenantName = "ps";
                break;
            case "749EB3E7-C7DB-4061-AA7B-81880DEE3768".toLowerCase():
                tenantName = "lucina";
                break;
            case "01391069-C04B-4650-9418-2962EAF72DD6".toLowerCase():
                tenantName = "ellucina";
                break;
            case "5F1AB789-4590-460A-ACB6-04FEBD4E22AA".toLowerCase():
                tenantName = "elps";
                break;
            //lab
            case "30a5704a-28c1-4c02-9573-66b8bebd35a6".toLowerCase():
                tenantName = "rsmc";
                break;
            case "54b9e65e-9b51-414c-a029-fbdc67adf81a".toLowerCase():
                tenantName = "ps";
                break;
            case "8e5a8b74-c2ea-4f0d-9f49-60577e4da6e9".toLowerCase():
                tenantName = "lucina";
                break;
            case "01391069-C04B-4650-9418-2962EAF72DD6".toLowerCase():
                tenantName = "ellucina";
                break;
            case "5F1AB789-4590-460A-ACB6-04FEBD4E22AA".toLowerCase():
                tenantName = "elps";
                break;

            // case "7EADA835-A15C-446A-942A-42E2F7BCB3F4".toLowerCase():
            //     tenantName = "CLM1"

            default:
                tenantName = null;
        }
        return tenantName;
    }
    */
    onFeedsReceived(data: any): Promise<void> {
        console.log("[Plugin] %s, onFeedsReceived: %o", this.appId, data);
        if (_.isEqual(data, this._currentFeeds)) { return Promise.resolve(); }

        // find for new feeds
        let newFeeds = _.differenceBy(data, this._currentFeeds, (x) => {
            return x.id;
        });
        console.log("[Plugin] %s, New feeds: %o", this.appId, newFeeds);

        // update menu
        if (newFeeds) {
            this._rootMenu.showBadge = true;
            this.onMenuUpdated$.next([this._rootMenu]);
        }

        // this._sampleService.saveFeeds(data);
        // console.log("[Plugin] %s, _sampleService: %o", this.appId, this._sampleService);
    }

    onCardAction(actionName: string, args: any){
        return null;
    }

    onToolDisabled(): Promise<void> {
        return Promise.resolve();
    }
}
